export type TypefaceKey = 'wide' | 'normal' | 'strong'

type Typeface = {
  [K in TypefaceKey]: string
}

export const typeface: Typeface = {
  wide: "'obviously-wide', 'verdana', sans-serif",
  normal: "'steradian', 'avenir', 'arial', sans-serif",
  strong: "'shackleton', 'copperplate', serif"
}
