exports.components = {
  "component---src-components-product-single-merch-page-tsx": () => import("./../../../src/components/product/SingleMerchPage.tsx" /* webpackChunkName: "component---src-components-product-single-merch-page-tsx" */),
  "component---src-components-product-single-product-page-tsx": () => import("./../../../src/components/product/SingleProductPage.tsx" /* webpackChunkName: "component---src-components-product-single-product-page-tsx" */),
  "component---src-components-product-variety-pack-page-tsx": () => import("./../../../src/components/product/VarietyPackPage.tsx" /* webpackChunkName: "component---src-components-product-variety-pack-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-collections-merch-tsx": () => import("./../../../src/pages/collections/merch.tsx" /* webpackChunkName: "component---src-pages-collections-merch-tsx" */),
  "component---src-pages-find-bawi-tsx": () => import("./../../../src/pages/find-bawi.tsx" /* webpackChunkName: "component---src-pages-find-bawi-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

