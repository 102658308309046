import { typeface, palette } from './'

export type TypeStyleKey = 'header' | 'paragraph' | 'largeSerif' | 'eyebrow' | 'button' | 'footnote'

type TypeStyleCollection = {
  [K in TypeStyleKey]?: string
}

export const typeStyle: TypeStyleCollection = {
  header: `
    margin-bottom: 0em;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: ${typeface.strong};
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.1em;
    svg {
      display: inline-block;
      vertical-align: bottom;
      height: 3rem;
      width: 3rem;
    }
    @media only screen and (min-width: 744px) {
      font-size: 3.61vw;
      svg {
        height: 3.61vw;
        width: 3.61vw;
      }
    }
    @media only screen and (min-width: 1440px) {
      font-size: 5.2rem;
      svg {
        height: 5.2rem;
        width: 5.2rem;
      }
    }
  `,
  paragraph: `
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: ${typeface.normal};
    font-size: 1.6rem;
    svg {
      display: inline-block;
      vertical-align: bottom;
      height: 1.6rem;
      width: 1.6rem;
    }
  `,
  largeSerif: `
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: ${typeface.strong};
    font-size: 5rem;
    line-height: 1em;
    svg {
      display: inline-block;
      vertical-align: bottom;
      height: 5rem;
      width: 5rem;
      margin: 0 0.1em;
      &:nth-of-type(4n+1) {
        transform: scale(1.4) rotateZ(10deg);
      }
      &:nth-of-type(4n+2) {
        transform: scale(1.4) rotateZ(-10deg);
      }
      &:nth-of-type(4n+3) {
        transform: scale(1.4) rotateZ(12deg);
      }
      &:nth-of-type(4n+4) {
        transform: scale(1.4) rotateZ(-15deg);
      }
    }
    @media only screen and (min-width: 744px) {
      font-size: 5.55vw;
      svg {
        height: 5.55vw;
        width: 5.55vw;
      }
    }
    @media only screen and (min-width: 1440px) {
      font-size: 8rem;
      svg {
        height: 8rem;
        width: 8rem;
      }
    }
  `,
  eyebrow: `
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: ${typeface.wide};
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 500;
    svg {
      display: inline-block;
      vertical-align: bottom;
      height: 1.6rem;
      width: 1.6rem;
    }
    @media only screen and (min-width: 744px) {
      font-size: 1.2vw;
      svg {
        height: 1.2vw;
        width: 1.2vw;
      }
    }
    @media only screen and (min-width: 1440px) {
      font-size: 1.8rem;
      svg {
        height: 1.8rem;
        width: 1.8rem;
      }
    }
  `,
  footnote: `
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
    font-family: ${typeface.wide};
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 0.8rem;
    svg {
      display: inline-block;
      vertical-align: bottom;
      height: 0.8rem;
      width: 0.8rem;
    }
    @media only screen and (min-width: 744px) {
      font-size: 0.7vw;
      svg {
        height: 0.7vw;
        width: 0.7vw;
      }
    }
    @media only screen and (min-width: 1440px) {
      font-size: 1rem;
      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  `,
  button: `
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 42px;
    padding: 8px 32px;
    @media only screen and (min-width: 744px) {
      height: 42px;
      padding: 0 32px;
      white-space: nowrap;
    }
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }

    font-family: ${typeface.strong};
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.5px;
    color: ${palette.black};
    a {
      color: ${palette.black};
      text-decoration: none;
    }

    border: 2px solid black;
    border-radius: 8px; 
    background: white;
    box-shadow: 0px 3px #000;

    cursor: pointer;  
    transition: transform 0.15s ease-in-out, box-shadow 0.1s ease-in-out;
    &:hover {
      color: ${palette.black};
      transform: translateY(-2px);
      box-shadow: 0px 5px #000;
    }
  `,
}
