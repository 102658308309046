import React from 'react'
import { SiteStateContext, SiteDispatchContext } from './Provider'


export const useSiteState = () => {
  const context = React.useContext(SiteStateContext)
  if (context === undefined) {
    throw new Error('useSiteState must be used within a SiteStateProvider')
  }
  return context
}


export const useSiteDispatch = () => {
  const context = React.useContext(SiteDispatchContext)
  if (context === undefined) {
    throw new Error('useSiteDispatch must be used within a SiteDispatchProvider')
  }
  return context
}
