export type PaletteKey = 'red' | 'plum' | 'orange' | 'green' | 'white' | 'black' | 'tan' | 'lime' | 'pink' | 'yellow'

type Palette = {
  [K in PaletteKey]: string
}

export const palette: Palette = {
  red: 'rgb(250, 42, 28)',
  plum: 'rgb(143, 39, 79)',
  orange: 'rgb(238, 94, 2)',
  green: 'rgb(33, 136, 38)',
  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)',
  
  tan: 'rgb(255, 232, 170)', 
  lime: 'rgb(128, 238, 116)',
  pink: 'rgb(248, 210, 218)',
  yellow: 'rgb(230, 228, 20)',
}
