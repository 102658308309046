// list all state variables and their possible values here
// ex. someBoolean: boolean
//     someString: string
//     someEnum: 'red' | 'yellow' | 'blue'

export type Language = 'english' | 'spanish' | 'spanglish'


export interface State {
  language: Language
  
  productShelfVisible: boolean
  mobileNavigationVisible: boolean
  ingredientShelfVisible: boolean
  ingredientShelfCopy?: any
  ingredientShelfImage?: string
  ingredientShelfColor: string
  ingredientShelfForegroundColor: string
  
  productPopupVisible: boolean
  productPopup?: {
    variant: GatsbyTypes.ShopifyProductVariant
    product: Partial<GatsbyTypes.SanityProduct>
  }
  
  dismissedCouponCode?: string
  couponVisible: boolean
}


export const initialState: State = {
  language: 'english',
  
  productShelfVisible: false,
  mobileNavigationVisible: false,
  ingredientShelfVisible: false,
  ingredientShelfCopy: undefined,
  ingredientShelfImage: undefined,
  ingredientShelfColor: 'black',
  ingredientShelfForegroundColor: 'white',
  
  productPopupVisible: false,
  productPopup: undefined,
  
  dismissedCouponCode: (typeof localStorage === 'undefined') ? undefined : localStorage?.getItem('dismissedCouponCode') || undefined,
  couponVisible: false,
}
