import { State, initialState } from './state'
import { SiteStateAction } from './actions'

export function siteStateReducer(state: State, action: SiteStateAction): State {
  switch (action.type) {
    case 'setLanguage': {
      return { ...state, language: action?.payload || initialState.language }
    }
    case 'toggleProductShelf': {
      return { ...state, productShelfVisible: !state.productShelfVisible }
    }
    case 'toggleMobileNavigation': {
      return { ...state, mobileNavigationVisible: !state.mobileNavigationVisible }
    }
    case 'setIngredientShelf': {
      return {
        ...state,
        ingredientShelfVisible: true,
        ingredientShelfCopy: action.payload.copy,
        ingredientShelfImage: action.payload.image,
        ingredientShelfColor: action.payload.color,
        ingredientShelfForegroundColor: action.payload.colorForeground,
      }
    }
    case 'dismissIngredientShelf': {
      return {
        ...state,
        ingredientShelfVisible: false,
      }
    }
    case 'showProductPopup': {
      return {
        ...state,
        productPopupVisible: !state.productPopupVisible,
        productPopup: action.payload,
      }
    }
    case 'dismissAll': {
      return {
        ...state,
        productShelfVisible: false,
        mobileNavigationVisible: false,
        ingredientShelfVisible: false,
        productPopupVisible: false,
        productPopup: undefined,
        couponVisible: false,
      }
    }
    case 'showCoupon': {
      return {
        ...state,
        couponVisible: true
      }
    }
    case 'dismissCouponCode': {
      if( typeof localStorage !== 'undefined' ) {
        if( action.payload ) {
          localStorage.setItem('dismissedCouponCode', action.payload)
        }
      }
      return {
        ...state,
        dismissedCouponCode: action.payload || state.dismissedCouponCode,
        couponVisible: false,
      }
    }
    default: {
      // note: thanks to typescript rules, this should be un-reachable
      throw new Error(`Unhandled action`)
    }
  }
}
