import React, { useEffect } from 'react'

const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = url
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

const StoreLocator = () => {
  useScript(
    `https://cdn.storepoint.co/api/v1/js/${process.env.GATSBY_STOREPOINT_ID}.js`
  )

  return (
    <div
      id="storepoint-container"
      data-map-id={process.env.GATSBY_STOREPOINT_ID}
    />
  )
}

export default StoreLocator
