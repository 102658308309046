export type LogoPaletteKey = 'red' | 'green' | 'orange' | 'plum' | 'redNoShadow'

type LogoPaletteCollection = {
  [K in LogoPaletteKey]: LogoPalette;
}

export interface LogoPalette {
  shadow: string
  insideShadow: string
  stroke: string
  color: string
}


export const logoPalette: LogoPaletteCollection = {
  red: {
    shadow: 'rgb(108, 13, 14)',
    insideShadow: 'rgb(119, 17, 19)',
    stroke: 'rgb(200, 32, 39)',
    color: 'rgb(237, 48, 38)',
  },
  redNoShadow: {
    shadow: 'rgba(255, 255, 255, 1)',
    insideShadow: 'rgba(255, 255, 255, 1)',
    stroke: 'rgb(255, 255, 255, 1)',
    color: 'rgb(237, 48, 38)',
  },
  green: {
    shadow: 'rgb(22, 54, 27)',
    insideShadow: 'rgb(22, 54, 27)',
    stroke: 'rgb(27, 90, 45)',
    color: 'rgb(36, 135, 65)',
  },
  orange: {
    shadow: 'rgb(119, 17, 20)',
    insideShadow: 'rgb(119, 17, 20)',
    stroke: 'rgb(208, 83, 39)',
    color: 'rgb(235, 98, 37)',
  },
  plum: {
    shadow: 'rgb(81, 21, 42)',
    insideShadow: 'rgb(81, 21, 42)',
    stroke: 'rgb(114, 29, 59)',
    color: 'rgb(178, 46, 101)',
  },
}
