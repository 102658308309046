import React from 'react'
import { State, initialState } from './state'
import { Dispatch } from './actions'
import { siteStateReducer } from './reducer'


export const SiteStateContext = React.createContext<State | undefined>(initialState)

export const SiteDispatchContext = React.createContext<Dispatch | undefined>(undefined)


interface ProviderProps {
  children: React.ReactNode
}

const Provider = ({ children }: ProviderProps) => {
  const [state, dispatch] = React.useReducer(siteStateReducer, initialState)
  return (
    <SiteStateContext.Provider value={state}>
      <SiteDispatchContext.Provider value={dispatch}>
        {children}
      </SiteDispatchContext.Provider>
    </SiteStateContext.Provider>
  )
}


export default Provider