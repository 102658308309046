/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react'
import { SiteProvider, ShopifyProvider } from '@utilities'


export const wrapRootElement = ({ element }) => (
  <ShopifyProvider>
    <SiteProvider>
      {element}
    </SiteProvider>
  </ShopifyProvider>
)
